/* =========================================
   1) Remove border from the entire calendar
   2) Style the navigation bar
   3) Add border around the view container only
========================================= */
.large-calendar .react-calendar {
    border: none;
    border-radius: 0;
    width: 100%; 
  }
  
  .large-calendar .react-calendar__navigation {
    /* Rounded background for the top bar */
    background: rgba(225, 233, 238, 0.50);
    border-radius: 30px;
  
    /* Spacing around the nav bar */
    margin-bottom: 1rem;
    padding: 0.5rem;
    min-height: 40px;
  
    /* Flexbox to align items properly */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  /* Make the month arrows (buttons) larger by increasing font-size */
  .large-calendar .react-calendar__navigation button {
    font-size: 1.5rem;       /* Increase arrow size */
    font-family: 'Inter', sans-serif;
    font-weight: 600;
  }
  
  /* Style the month label (the current month & year text) */
  .large-calendar .react-calendar__navigation__label {
    color: #406074;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 2rem;         /* Make it "a lot larger" than default */
  }
  
  /* The main "grid" container (weekdays + day tiles) */
  .large-calendar .react-calendar__viewContainer {
    border: 1px solid #C8D7E1;
    border-radius: 10px 10px 10px 10px;
  }
  
  /* =========================================
     Weekday Header Styles
  ========================================= */
  .large-calendar .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 1.5rem;
  }
  
  .large-calendar .react-calendar__month-view__weekdays__weekday {
    min-height: 70px; 
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* =========================================
     Day Number (Tile) Styles
  ========================================= */
  .large-calendar .react-calendar__tile {
    position: relative;  /* CRUCIAL for anchoring absolute elements */
    display: block;      /* So the tile can size naturally (optional, but often helps) */
    font-family: 'Inter', sans-serif;
    font-size: 1.8rem;
    font-weight: 600;
    min-height: 100px; 
    min-width: 80px; 
  }
  
  /* Remove hover, active, and selection backgrounds */
  .large-calendar .react-calendar__tile:enabled:hover {
    background: transparent;
  }
  
  .large-calendar .react-calendar__tile--active,
  .large-calendar .react-calendar__tile--active:focus,
  .large-calendar .react-calendar__tile--active:hover,
  /* Remove any range highlights, brute forced because I can't find exactly where it is highlighting the range (which we dont need) */
  .large-calendar .react-calendar__tile--active,
.large-calendar .react-calendar__tile--active:hover,
.large-calendar .react-calendar__tile--active:focus,
.large-calendar .react-calendar__tile--range,
.large-calendar .react-calendar__tile--rangeStart,
.large-calendar .react-calendar__tile--rangeEnd,
.large-calendar .react-calendar__tile--range:hover,
.large-calendar .react-calendar__tile--range:focus,
.large-calendar .react-calendar__tile--rangeStart:hover,
.large-calendar .react-calendar__tile--rangeStart:focus,
.large-calendar .react-calendar__tile--rangeEnd:hover,
.large-calendar .react-calendar__tile--rangeEnd:focus {
  background: transparent !important;
  color: inherit !important;
  outline: none !important;
  box-shadow: none !important; /* If there's a box-shadow highlight */
}
  
  /* Optional: weekday row background */
  .large-calendar .react-calendar__month-view__weekdays {
    background: rgba(197, 225, 243, 0.27);
  }

  /* 1) Slight offset for the arrow icons */
  .large-calendar .react-calendar__navigation button svg {
    /* Moves the icon ~2px down and ~2px right; adjust to taste */
    transform: translate(2px, 2px);
  }
  
  /* 2) Remove hover/background highlight on arrows & month/year label */
  .large-calendar .react-calendar__navigation button:enabled:hover,
  .large-calendar .react-calendar__navigation button:enabled:focus,
  .large-calendar .react-calendar__navigation__label:enabled:hover,
  .large-calendar .react-calendar__navigation__label:enabled:focus {
    background: transparent !important;
    cursor: pointer; /* Keep pointer cursor without the background highlight */
  }
  
  /* (Optional) Also ensure no border or background is applied by default */
  .large-calendar .react-calendar__navigation button {
    background: transparent;
    border: none;
  }
  /* Ensures that the day number is always on top of those circles */
  .large-calendar .react-calendar__month-view__days__day > abbr {
    position: relative; /* Needed for z-index to take effect */
    z-index: 10;        /* Adjust as desired */
  }
  
  .large-calendar .react-calendar__viewContainer {
    padding-bottom: 1rem; /* or however much space you like */
  }

.large-calendar .react-calendar__tile--now,
.large-calendar .react-calendar__tile--now:enabled:hover,
.large-calendar .react-calendar__tile--now:enabled:focus {
  background: transparent !important;
}
  